import { useEffect, useRef, useState } from 'react';
import banner from '../../assets/images/proximos/banner_desktop.png';
import banner_mobile from '../../assets/images/proximos/banner_mobile.png';
import $ from 'jquery';
import dolar from '../../assets/images/proximos/icon-dolar.svg';
import gift from '../../assets/images/proximos/icon-gift.svg';
import pig from '../../assets/images/proximos/icon-pig.svg';
import trofeo from '../../assets/images/proximos/icon-trofeo.svg';
import bgform from '../../assets/images/proximos/bg-form.png';
import second_project from '../../assets/images/proximos/second-project.png';
import icon from '../../assets/images/proximos/icon-star.png';
import keepDown from '../../assets/images/proximos/icon-keep-down.svg';
import { motion, useAnimation, useInView } from 'framer-motion';

import './style.scss';
import { checkableBoolProps, FormContainer, setInputProps } from '../../component/common/forms/Form';
import { CRM_MEDIUMS, PROJECTS_DATA } from '../../data/projects';
import { CommingSoonValidatonSchema } from '../../component/common/forms/constraints/ValidatonSchemas';
import { LeadService } from '../../component/common/Http/LeadService';
export const ProximosProyectos = () => {
	const [activePopup, setActivePopup] = useState(false);
	const attrs = [
		{
			title: 'Accede a un <span>precio exclusivo<span>',
			icon: dolar,
		},
		{
			title: 'Sé uno de los primeros <span>en elegir tu depa</span>',
			icon: trofeo,
		},
		{
			title: '<span>Obtén facilidades</span> de financiamiento',
			icon: pig,
		},

		{
			title: 'Llévate un <span>regalo especial</span>',
			icon: gift,
		},
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<main className='proximosproyectos'>
			<div className='relative banner'>
				<picture>
					<source srcSet={banner} media='(min-width: 500px)'></source>
					<img src={banner_mobile} alt='' className='img-fluid w-full'></img>
				</picture>
				<div className='move-down' onClick={() => $('html, body').animate({ scrollTop: $('.benefits').offset().top - 150 }, 200)}></div>
			</div>
			<div className='benefits'>
				<div className='circle circle-1 mini-ball-white-1'></div>
				<div className='circle circle-2 mini-ball-white-1'></div>
				<div className='container'>
					<h1 className='text-white'>
						<span className='gotham-500'>Beneficios de ser parte</span> <br className='mobile'></br>
						de la lista preferente
					</h1>
					<div className='content-attr'>
						{attrs.map((item, index) => (
							<Attr item={item} index={index}></Attr>
						))}
					</div>
				</div>
			</div>
			<div className='relative d-flex flex-col'>
				<div className='circle circle-3 mini-ball'></div>
				<div className='circle circle-4 mini-ball'></div>
				<div className='container container-form'>
					<div className='ctn-forms first_project'>
						<div className='inf'>
							<span className='_sub'>UBICACIÓN</span>
							<h2 className='tl'>
								<span className='c-third'>Ca. Lanceros 435, </span>
								<br></br>
								<span className='c-primary'>Surco</span>
							</h2>
							<div className='line'></div>
							<div className='flex flex-col ctn-info'>
								<ComponentMovement index={1}>
									<div className='item-info'>
										<div className='mask icon-dorm'></div>
										<span className='title-inf'>
											<strong>1 · 2 · 3 Dorms.</strong>
										</span>
									</div>
								</ComponentMovement>
								<ComponentMovement index={2}>
									<div className='item-info'>
										<div className='mask icon-sheet'></div>
										<span className='title-inf'>
											<strong>+7,700M²</strong> de parque privado
										</span>
									</div>
								</ComponentMovement>
								<ComponentMovement index={3}>
									<div className='item-info'>
										<div className='mask icon-bag'></div>
										<span className='title-inf'>
											Frente al <strong>Jockey Plaza</strong>
										</span>
									</div>
								</ComponentMovement>
							</div>
							<div className='d-flex flex-row items-center keep cursor-pointer' onClick={() => $('html, body').animate({ scrollTop: $('.second_project').offset().top - 150 }, 200)}>
								<img className='icon-keep' src={keepDown}></img>
								<span className='title-inf'>
									Desliza para <strong>ver otro más</strong>
								</span>
							</div>
						</div>
						<ImageProject src={bgform}></ImageProject>
						<FormCommingSoon id={36} setActivePopup={setActivePopup}></FormCommingSoon>
					</div>
				</div>
				<div className={`popup ${activePopup ? 'active' : ''}`}>
					<div className='content-popup'>
						<div
							className='btn-close'
							onClick={() => {
								setActivePopup(false);
							}}
						>
							<img src={require('../../assets/images/icons/btn-close.svg').default}></img>
						</div>
						<img className='background desktop' src={require('../../assets/images/icons/background-modal.png')}></img>
						<img className='background movil' src={require('../../assets/images/icons/background-modal-movil.png')}></img>
						<img className='icon-check' src={require('../../assets/images/icons/check_icon.svg').default}></img>
						<span className='title'>¡Gracias!</span>
						<span className='sub-title'>Dentro de poco un asesor se pondrá en contacto contigo.</span>
					</div>
				</div>
				<div className='container container-form second_project'>
					<div className='ctn-forms'>
						<div className='inf'>
							<span className='_sub'>UBICACIÓN</span>
							<h2 className='tl'>
								<span className='c-third'>
									Av. Óscar R.<br></br> Benavides 3866,{' '}
								</span>
								<br></br>
								<span className='c-primary'>Bellavista</span>
							</h2>
							<div className='line'></div>
							<div className='flex flex-col ctn-info'>
								<ComponentMovement index={2}>
									<div className='item-info'>
										<div className='mask icon-dorm'></div>
										<span className='title-inf'>
											<strong>1 · 2 · 3 Dorms.</strong>
										</span>
									</div>
								</ComponentMovement>
								<ComponentMovement index={3}>
									<div className='item-info'>
										<div className='mask icon-bag'></div>
										<span className='title-inf'>
											Al lado del <strong>Mallplaza Bellavista</strong>
										</span>
									</div>
								</ComponentMovement>
								<ComponentMovement index={4}>
									<div className='item-info'>
										<div className='mask icon-transporte '></div>
										<span className='title-inf'>
											Frente a la futura estación de la <strong>Línea 2</strong>
										</span>
									</div>
								</ComponentMovement>
							</div>
						</div>
						<ImageProject src={second_project}></ImageProject>
						<FormCommingSoon id={37} setActivePopup={setActivePopup}></FormCommingSoon>
					</div>
				</div>
			</div>
		</main>
	);
};

const ImageProject = ({ src }) => {
	const ref = useRef(null);
	const controls = useAnimation();
	const isInView = useInView(ref);
	useEffect(() => {
		if (isInView) {
			controls.start('visible').catch(err => {
				console.log(err);
			});
		}
	}, [controls, isInView]);
	return (
		<motion.div
			className='img'
			ref={ref}
			animate={controls}
			initial='hidden'
			transition={{ duration: 0.8, delay: 0.2 * 1 }}
			variants={{
				hidden: window.innerWidth < 500 ? { opacity: 1 } : { opacity: 0, y: 100 },
				visible: { opacity: 1, y: 0 },
			}}
		>
			<img className='w-full' src={src}></img>
		</motion.div>
	);
};

const ComponentMovement = ({ children, index }) => {
	const ref = useRef(null);
	const controls = useAnimation();
	const isInView = useInView(ref);
	console.log('object', isInView);
	useEffect(() => {
		if (isInView) {
			controls.start('visible').catch(err => {
				console.log(err);
			});
		}
	}, [controls, isInView]);
	return (
		<motion.div
			className='component-movement'
			ref={ref}
			initial='hidden'
			animate={controls}
			transition={{ duration: 0.6, delay: 0.2 * index }}
			variants={{
				hidden: { opacity: 0, y: 100 },
				visible: { opacity: 1, y: 0 },
			}}
		>
			{children}
		</motion.div>
	);
};

const Attr = ({ item, index }) => {
	const ref = useRef(null);
	const controls = useAnimation();
	const isInView = useInView(ref);
	useEffect(() => {
		if (isInView) {
			controls.start('visible').catch(err => {
				console.log(err);
			});
		}
	}, [controls, isInView]);
	return (
		<motion.div
			key={index}
			className='attr'
			ref={ref}
			animate={controls}
			initial='hidden'
			transition={{ duration: 0.6, delay: 0.2 * index }}
			variants={{
				hidden: window.innerWidth < 500 ? { opacity: 1 } : { opacity: 0, y: 100 },
				visible: { opacity: 1, y: 0 },
			}}
		>
			<img src={item.icon} className='icon' alt={item.title}></img>
			<h3 className='attr-title' dangerouslySetInnerHTML={{ __html: item.title }}></h3>
		</motion.div>
	);
};

const FormCommingSoon = ({ id, setActivePopup }) => {
	const [initialValues, setInitialValues] = useState({
		fname: '',
		lname: '',
		email: '',
		telephone: '',
		motive: '',
		project: id,
		time_to_contact: '',
		terms: false,
	});
	const submitForm = (values, { resetForm }) => {
		const leadService = new LeadService();

		const projects = {
			37: { id: 37, name: 'Próximo', environment: 'cosapi', comingSoon: true, ...CRM_MEDIUMS.cosapi },
			36: { id: 36, name: 'Bosqo', environment: 'cosapi', comingSoon: true, ...CRM_MEDIUMS.cosapi },
		};
		const project = projects[values.project];
		// console.log(values, project)
		Object.assign(values, {
			input_channel_id: project.input_channels.web,
			source_id: project.source_ids.web,
		});
		let delay = setInterval(() => {
			resetForm();
			clearInterval(delay);
		}, 1000);
		leadService
			.setProject(project)
			.setLeadData(values)
			.save()
			.then(client => {
				window.fbq && window.fbq('track', 'Lead', { project: project.name });
				setActivePopup(true);
				this.onSuccess(client.last_agent_assigned.phone);
			})
			.catch(e => {
				console.error(e);
			});
	};

	return (
		<div className='formulario'>
			<img className='icon' src={icon}></img>
			<h3 className='tl_form c-third'>
				Regístrate en nuestra <br></br>
				<span className='t1 c-third'>lista preferente</span>
			</h3>
			<FormContainer initialValues={initialValues} validationSchema={CommingSoonValidatonSchema} onSubmit={submitForm}>
				{form => {
					const { handleSubmit, isSubmitting } = form;
					return (
						<form className='form' onSubmit={handleSubmit}>
							<div className='content-inputs'>
								<input placeholder='Nombre' {...setInputProps('fname', '', form)}></input>
								<input placeholder='Apellidos' {...setInputProps('lname', '', form)}></input>
								<input placeholder='Teléfono' {...setInputProps('telephone', '', form)}></input>
								<input placeholder='Correo electrónico' {...setInputProps('email', '', form)}></input>

								<select {...setInputProps('motive', '', form)}>
									<option value=''>Motivo de compra</option>
									<option value='Primera Vivienda'>Primera Vivienda</option>
									<option value='Inversión'>Inversión</option>
								</select>
							</div>
							<div className='politicas'>
								{/* <Field id="terms" type="checkbox" name="terms" /> */}
								<input id={'terms' + id} type='checkbox' {...checkableBoolProps('terms', '', form)}></input>
								<label htmlFor={'terms' + id} className='label-accept'>
									<div className='check'></div>
								</label>
								<span className='terms'>
									Acepto las{' '}
									<a target='_blank' href={require('../../assets/files/Politica-de-privacidad.pdf')}>
										políticas de privacidad
									</a>
								</span>
							</div>
							<button type='submit' className='btn-submit btn-radio c-white b-primary' disabled={isSubmitting}>
								{isSubmitting ? 'Enviando...' : 'Enviar'}
							</button>
						</form>
					);
				}}
			</FormContainer>
		</div>
	);
};
